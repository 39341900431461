@import "button";


@keyframes jiggle {
    0% {
        transform: translate(1px, 1px) rotate(1deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-2deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(2deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(1deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(2deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-2deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(1deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-2deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(2deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(1deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-2deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(2em);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// Index
:root {
    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    --font-size: 16px;
    --font-heading: "Inter", sans-serif;
    --font-editorial: "Pitch Sans", sans-serif;
    // --font-body: "Inter", sans-serif;
    --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
        sans-serif;
    --font-line-height-body: 1.375em;
    --font-line-height-heading: 1.25em;
    --font-line-height-lg-heading: 1.125em;
    --screen-xl: 1680px;
    --screen-lg: 1280px;
    --screen-md: 976px;
    --screen-sm: 736px;
    --animation-step: 0.125s;
    --focus-box-shadow: 0 0 0 1.5em var(--primary-color);
    --focus-box-shadow-color: var(--primary-color);
    --border-width: 1px;
    --border-radius: 1em;
    --max-width: 1460px;
    --frame: var(--color-primary-lp1);


    --space: calc(var(--font-size) * 2); // = 24px
    --border-radius: calc(var(--space) * 1.25);

    // C0L0R
    --color-foreground: hsla(55, 100%, 99%, 1);
    --color-background: hsla(274, 78%, 4%, 1);

    --color-primary: hsla(110, 100%, 52%, 1);
    --color-secondary: hsla(190, 100%, 76%, 1);
    --color-tertiary: hsla(40, 100%, 65%, 1);
    --color-quartinary: hsla(100, 100%, 82%, 1);
    --color-quinary: hsla(0, 100%, 94%, 1);
    --color--senary: hsla(26, 100%, 60%, 1);

    --color-black: #000;
    --color-white: #fff;

    --color-green: hsl(128, 93%, 47%);
    --color-red: rgb(255, 65, 44);
    --color-red-02: hsla(6, 100%, 69%, 1);
    --color-red-a02: hsla(6, 100%, 69%, 0.2);
    --color-red-03: hsla(16, 100%, 72%, 1);
    --color-red-05: hsla(16, 100%, 50%, 1);
    --color-red-06: hsla(16, 100%, 55%, 1);
    --color-red-07: hsla(16, 100%, 60%, 1);
    --color-red-08: hsla(16, 100%, 61%, 1);
    --color-red-lm2: hsla(350, 100%, 30%, 1);

    --color-shadow: hsla(210, 10%, 92%, 1);
    --color-shadow-light: hsla(218, 12%, 96.5%, 0.9);
    --color-shadow-lighter: hsla(218, 8%, 95%, 1);
    --color-shadow-darker: hsla(218, 14%, 85%, 1);
    --color-shadow-darkest: hsla(218, 14%, 75%, 1);
    --color-grey: rgb(159, 167, 182);
    --color-grey-darker: hsla(218, 7%, 51%, 1);
    --color-p: hsla(218, 27%, 18%, 1);

    // xs
    @media (max-width: 600px) {
        --space: calc(var(--font-size) * 1.25); // = 26px
        --border-radius: calc(var(--space) * 0.5);
    }

    // sm
    @media (min-width: 767px) {
        --space: calc(var(--font-size) * 2); // = 32px
    }

    // md
    @media (min-width: 976px) {
        --space: calc(var(--font-size) * 2.25); // = 36px
    }

    // lg
    @media (min-width: 1460px) {
        --space: calc(var(--font-size) * 2.875); // = 46px
    }
}


// .ex {
//     background: var(--accent-color from "./vars.module.css");
// }

// Layout
html {
    height: 100vh;
    background-color: var(--color-background);
}


body {
    overflow-x: hidden;
    min-height: 100vh;
    margin: 0;
    color: var(--color-foreground);
}

article {
    max-width: var(--max-width);
    padding: var(--space);
    min-height: 100%;
    gap: var(--space);
    display: grid;

    >*:nth-of-type(1),
    >*:nth-of-type(3) {
        border-radius: 0;
        background: none !important;
        box-shadow: none !important;
    }

    >*:nth-of-type(1) {
        order: 1;
    }


    @media (min-width: 40rem) {

        grid-template-columns: repeat(6, 1fr);

        >*:nth-of-type(1) {
            order: unset;
        }

        >*:nth-of-type(1) {
            grid-column: span 4;
            grid-row: span 3;


        }

        >*:nth-of-type(2) {
            grid-column: span 2;
            position: sticky;
            top: calc(var(--space) * 2);
            box-shadow: inset 0 0 5em 0 hsla(0, 100%, 100%, 0.075);
            margin-bottom: calc(var(--space) * 2);
            z-index: 2;
        }

        >*:nth-of-type(3) {
            grid-column: span 3;
            // grid-row: span 3;
            z-index: 1;
            position: sticky;
            top: var(--space);
            padding-left: calc(var(--space) / 2);
            padding-left: 0;
            pointer-events: none;
            margin-left: calc(-2 * var(--space));
            border-top: var(--color-foreground) 0.5px solid;
        }

        >*:nth-of-type(4) {
            grid-column: span 4;
            grid-row: span 1;
        }
    }
}

section {
    z-index: 2;
    color: var(--color-foreground);
    border-radius: calc(var(--border-radius) / 6);
    padding: var(--space);
    // border: 0.25px solid var(--color-shadow);
    background: hsla(0, 0%, 100%, 0.175);
    transition:
        border-radius calc(var(--animation-step) * 2) cubic-bezier(0.6, -1, 0.25, 3),
        box-shadow calc(var(--animation-step) * 1) ease-in-out,
        border-color calc(var(--animation-step) * 1) ease-in-out;
    display: grid;
    overflow: hidden;
    z-index: 0;
    position: relative;
    transition: 0.125s all ease-in-out 0, 0.25s transform ease-in-out 0.125s;

    svg {
        width: 100%;
    }
}

section#guba__terminator {

    min-height: 240em;

    padding: 0;
    border: 0;
    overflow: visible;

    span {
        // filter: url(#pixelate);
        transform: translate(0, 0);
    }

    svg {

        transform: translate(0, 0) scale(1.0375);
    }

    // filter: url("#Chromatic_aberration");
}

svg#gubathon__svg__23,
svg#guba__svg {

    g {}

    path {

        fill: #1E1823;
        // stroke: white;
        // stroke-width: 1em;
        stroke-miterlimit: 10;
    }
}

section#guba__eye {
    box-shadow: inset 0 0 5em #ffffff13, inset 1px 1px rgba(255, 255, 255, 0.3);
    // 
    // filter: url(#pixelate);

    i {
        position: relative;
        width: 100%;
        z-index: 0;

        &:before,
        &:after {
            content: '';
            width: 0.75em;
            height: 0.75em;
            position: absolute;
            display: block;
            border-radius: 111em;
            background-color: var(--color-background);
            left: 0;
            box-shadow: 1px 1px 0.5px 0 rgba(255, 255, 255, 0.1);
        }

        &:after {
            right: 0;
            left: unset;
        }

        &:nth-of-type(2) {

            &:before,
            &:after {
                margin-top: -0.675em;
            }
        }
    }
}

// // 
.toggleSwitch {

    box-sizing: border-box;
    width: 3em;
    outline: 0;
    padding: 0;
    height: 5em;
    position: relative;
    display: block;
    margin: 3em auto;
    border-radius: 111em;
    border: 0 !important;
    cursor: pointer;
    background: #111;
    z-index: 1;
    transition: 0.25s box-shadow ease 0.05s;

    &:before {
        transition: 0.25s box-shadow ease 0.05s;
        box-shadow: 0 0 1em 1px hsla(0, 100%, 100%, 0.1);
        width: 100%;
        height: 100%;
        background: none;
        content: "";
        display: block;
        position: absolute;
        z-index: -2;
        border-radius: 111em;
    }


    div {
        // filter: url("#goo");
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 111em;
        box-shadow: 1px 1px 0.5px 0 rgba(255, 255, 255, 0.2);
    }

    span {
        bottom: 0.5em;
        position: absolute;
        left: 0.5em;
        width: calc(100% - 1em);
        padding: calc(50% - 0.5em) 0;
        border-radius: 100em;
        // box-shadow: 0 0 0 1px #000;
        background-color: #fff;
        overflow: hidden;
        transition: 0.2s background-color ease 0.05s;

        &:after,
        &:before {
            opacity: 0;
            background-color: #fff;
            width: 90%;
            height: 90%;
            content: "";
            display: block;
            position: absolute;
            top: 5%;
            left: 5%;
            transform: scale(0);
            border-radius: 100em;
            transition: 0.1s transform cubic-bezier(0.6, -1, 0.25, 3) 0s, 0.2s opacity ease 0.05s;
        }


        &:last-of-type {
            transition: 0.3s transform cubic-bezier(0.6, -1, 0.25, 3) 0s, 0.1s background-color ease 0s;
            transform: translateY(0%) scale(0.5);
        }
    }

    &:hover {
        // &:before {
        //     box-shadow: 0 0 1em 1px hsla(0, 100%, 100%, 0.2);
        // }

        span {

            // &:first-of-type {
            // 	transition: 0.2s transform ease, 0.1s background-color ease 0s;
            // 	transform: translateY(0%) scale(0.75);
            // }
            &:last-of-type {
                transition: 0.3s transform cubic-bezier(0.6, -1, 0.25, 3) 0s, 0.1s background-color ease 0s;
                transform: translateY(0%) scale(0.5);
            }
        }
    }

    &.active {

        &:before {
            box-shadow: 0 0 1em 1px hsla(10, 100%, 50%, 0.25), 0 0 2em 1px hsla(4, 100%, 50%, 0.1);
        }

        span {
            // box-shadow: 0 0 0 1px red;
            transition: 0.2s background-color ease 0.5s;

            &:after {
                opacity: 0.8;
                transform: scale(1);
                transition: 0.2s opacity ease 0.5s;
            }

            // &:first-of-type {
            // 	transform: translateY(-100%) scale(1);
            // 	transition: 0.25s transform ease, 0.3s background-color ease 0.3s;


            // }
            &:last-of-type {
                background: radial-gradient(circle closest-side, var(--color-red), var(--color-red-02));
                transition: 0.3s transform cubic-bezier(0.6, -1, 0.25, 3) 0.1s, 0.1s background-color ease 0.2s;
                transform: translateY(-100%) scale(1.1);
            }
        }

        &:hover {
            span {

                // &:first-of-type {
                // 	transform: translateY(-100%) scale(0.75);
                // }
                &:last-of-type {
                    transform: translateY(-100%) scale(1);
                }
            }
        }
    }

    // &:hover {
    // 	box-shadow: 0 0 0.1rem 0.4rem rgba(226, 208, 199, 0.918);
    // }
}

@keyframes pulsing {
    from {
        transform: scale(1) translate(0, 0);
        background: radial-gradient(circle 110px, var(--color-red), var(--color-red-02));
        box-shadow: var(--color-red-02) 0 -1px 17px 1px, inset var(--color-red-02) 0 -1px 9px, var(--color-red) 0 12px 0;
    }

    50% {
        transform: scale(1.06) translate(0, 0);
        background: radial-gradient(circle 110px, var(--color-red), var(--color-red-02));
        box-shadow: var(--color-red-02) 0 -2px 1px 1px, inset var(--color-red-02) 0 -1px 9px, var(--color-red) 0 2px 0;
    }

    to {
        transform: scale(1) translate(0px, 0px);
        background: radial-gradient(circle 110px, var(--color-red), var(--color-red-02));
        box-shadow: var(--color-red-02) 0 -1px 17px 1px, inset var(--color-red-02) 0 -1px 9px, var(--color-red) 0 12px 0;
    }
}

.x2 {
    .toggleSwitch {
        cursor: pointer !important;
        font-size: 6vmin;
        background: #111;
        margin: calc(var(--space) * 2) auto;
        transition: 0.25s box-shadow ease 0.1s;
        // box-shadow: 0 0 0.125rem 0rem transparent;
        box-shadow: var(--color-red-a02) 0 0px 1px 1px;

        div {

            width: 100%;
            height: 100%;
            position: relative;
        }

        span {
            background-color: #fff;
            transition: 0.2s background-color ease 0.25s;
            filter: url("#goo");

            &:first-of-type {
                transition: 0.25s transform cubic-bezier(0.6, -1, 0.25, 3) 0s, 0.2s background ease 0.5s;
                // transform: translateY(0%) scale(0.9);
                z-index: 11;

                &:after,
                &:before {
                    box-shadow: var(--color-red-07) 0 -1px 7px 1px, inset #304701 0 -1px 9px, var(--color-red-02) 0 2px 12px;
                    // filter: url(#GaussianBlur_1);
                    background: var(--color-red-05);
                    z-index: 11;
                    content: "";
                    display: block;
                    position: absolute;
                    opacity: 0;
                    transform: scale(1.1);
                    border-radius: 100em;
                    transition: 0.2s transform ease 0.5s, 0.25s opacity ease 0s, 0.25s background ease s;
                }

                &:before {
                    z-index: 14;
                    box-shadow: var(--color-red-07) 0 -1px 15px 2px, inset var(--color-red-04) 0 -2px 12px, var(--color-red) 0 2px 12px;
                    background: var(--color-red-06);

                }
            }

            &:last-of-type {
                transition: 0.22s transform cubic-bezier(0.6, -1, 0.25, 3) 0.17s, 0.1s background ease 0s;
                // transform: translateY(0%) scale(0.8);


            }
        }

        &:hover {
            span {
                &:first-of-type {
                    transition: 0.25s transform ease 0s, 0.2s background ease 0s;
                }

                &:last-of-type {
                    transition: 0.32s transform ease 0.1s, 0.1s background ease 0s;
                }
            }
        }

        &.active {
            transition: 0.25s box-shadow ease 0.5s;

            // box-shadow: 0 0 5em 0.25em rgba(white, 0.125), 0 0 5em 0 rgba(lime, 0.4), 0 0 0.5em 0.125em rgba(lime, 0.23);
            span {
                transition: 0.2s background cubic-bezier(0.6, -1, 0.25, 3) 0.5s;

                &:first-of-type {
                    transform: translateY(-100%) scale(1);
                    transition: 0.32s transform cubic-bezier(0.6, -1, 0.25, 3) 1s, 0.3s background ease 0.3s;

                    &:after {
                        opacity: 0.5;
                        // transform: scale(1.05);
                        transition: 0.2s transform cubic-bezier(0.6, -1, 0.25, 3) 0.5s, 0.225s opacity ease 0.35s, 0.2s background ease 1s;
                    }

                    &:before {
                        opacity: 1;
                        background: var(--color-red-07);
                        // transform: scale(0.65) translate(-4px, -11px);
                        transition: 0.35s transform cubic-bezier(0.6, -1, 0.25, 3) 0.45s, 0.3s opacity ease 0.4375s, 0.5s background ease 1s;
                        animation: pulsing 3.33s infinite;
                    }
                }

                &:last-of-type {
                    transition: 0.15s transform cubic-bezier(0.6, -1, 0.25, 3) 1.5s, 0.1s background ease 0.2s;
                    transform: translateY(-100%) scale(1);
                }
            }

            &:hover {
                span {
                    &:first-of-type {
                        transition: 0.29s transform cubic-bezier(0.6, -1, 0.25, 1), 0.3s background ease 0.3s;
                    }

                    &:last-of-type {
                        transition: 0.26s transform cubic-bezier(0.6, -1, 0.25, 1.2) 0.075s, 0.1s background ease 0.2s;
                    }
                }
            }
        }

        &:hover {
            box-shadow: var(--color-red-a02) 0 0px 1px 1px, var(--color-red-a02) 0 0 2em, var(--color-red-a02) 0 0 0;
        }
    }
}

.terminator__bot {
    font-size: 1em;
    color: lime;
    z-index: 2;

    #text {
        font-weight: 200;
        text-align: center;
        margin: 0 auto;
        font-weight: 100;
        // text-transform: uppercase;
        letter-spacing: 0.1em;
        font-family: "SF Mono", "Segoe-Mono-Boot", monospace, monospace;
    }

    #cursor {
        font-weight: 100;
        display: inline-block;
        position: relative;
    }
}



// Draggable card
.window {
    font-size: 1em;
    font-weight: 200;
    width: 100%;
    transition: background 0.085s ease-in-out, border 0.085s ease-in-out;
    position: absolute;
    max-width: 26em;
    font-family: "SF Mono", "Segoe-Mono-Boot", monospace, monospace;

    @media (min-width: 40rem) {
        margin-top: 3em;
    }


    >div {
        display: block;
        opacity: 1;
        background: transparent;
        border: 1px solid green;
        box-shadow: 0 0.2em 0.5em 0em rgba(#67d167, 0.05), 0.2em 0.2em 0.2em 0 rgba(lime, 0.1),
            0 0 0.5em 0em rgba(lime, 0.1);
        transition: background calc(var(--animation-step) * 2) ease, border calc(var(--animation-step) * 2) ease,
            box-shadow calc(var(--animation-step) * 2) ease;
        // transform: rotate(1deg);
        display: grid;
        grid-template-rows: min-content 1fr;
        background-color: hsla(120, 100%, 50%, 0.04875);

        // Optional header
        .window__header {
            min-height: 3.5em;
            top: 0;
            text-align: left;
            position: relative;
            z-index: 111;
            margin-bottom: 0;
            border-bottom: 0.75px solid var(--color-green);
            display: flex;
            align-items: center;
            font-weight: 100;
            padding: 0em 0.25em 0em 1em;
            color: lime;


            abbr {
                display: flex;

                .icon {
                    font-size: 2em;
                    display: inline-block;

                }

                .txt {
                    font-size: 1em;
                    font-weight: 200;
                    display: inline-block;
                    letter-spacing: 0.1em;

                    a {
                        color: cyan;
                        text-decoration: none;

                        &:hover {
                            color: white;
                        }
                    }

                    &.--alt {
                        color: var(--color-foreground);
                        font-size: 1.5em;
                        font-weight: 400;
                    }
                }
            }

            .popup-close-button {
                font-weight: 200;
                margin-left: auto;
                top: 0;
                border: 0;
                border-radius: 0;
                padding: 0.25em;
                cursor: pointer;
                color: lime;
                font-size: 1.75em;
                background: transparent;
                height: 100%;
                min-height: 3.5rem;
                // transition: transform $animationStep * 3 $bigBouncyEase, color $animationStep/2 ease-in-out;
                display: inline-block;

                span {
                    display: block;
                    margin-top: -0.125em;
                    padding: 0 0.25em;
                    overflow: visible;
                }

                &:hover {
                    // color: var(--color-primary) !important;
                    transform: scale(1.2);
                }
            }
        }

        .window__contents {
            padding: 1em;
            min-height: 12em;
            color: green;
            text-align: left;
            letter-spacing: 0.1em;
            z-index: 2;
            position: relative;


            p {
                // font-style: italic;
                max-width: 90%;
                line-height: 1.375em;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;



                li {
                    visibility: hidden;
                    margin: 0;
                    position: relative;
                    width: 100%;

                    h2,
                    h3 {
                        font-weight: 300;
                        font-size: 1em;
                        display: inline;
                        margin: 0;

                    }

                    h2 {
                        padding: 0 0 0 1em;
                        color: lime;
                        line-height: 1.375em;

                        &:before {
                            content: '';
                            display: inline-block;
                            border-radius: 111em;
                            background-color: lime;
                            width: 0.5em;
                            height: 0.5em;
                            margin: 0.375em 0.5em 0 -1em;
                            position: absolute;
                        }
                    }

                    h3 {}

                    a {
                        @extend .goo-button, .goo-button--dope, .--xs, .--primary, .--round;
                        padding: 0.3em 0.5em 0.24em;
                        font-size: 0.75em;
                        margin: 0 0 0 0.25em;
                        background-color: lime !important;
                        opacity: 0.5;

                        &:hover {
                            opacity: 1;
                        }
                    }


                    >div {
                        padding: 1em;
                        // background-color: rgba(0,255,110, 0.05);
                        border-radius: 0.375em;

                        p {
                            font-weight: 200;
                            margin: 0.5em 0 0 0;
                            color: wheat;
                            // font-size: 0.875em;
                        }
                    }

                    // &:not(:last-child) {
                    //     padding: 0em 0 1em;
                    // }
                }
            }

            .goo-button {
                box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.875), 1px 1px 0 0 rgba(0, 0, 0, 0.5), 1px 1px 0 0 rgba(255, 0, 0, 1);
                font-weight: 500 !important;
                position: relative;
                background: rgba(lime, 0.85);
                color: var(--color-background) !important;
                touch-action: auto !important;
                transition: none;

                span {
                    font-family: unset;
                    font-size: 1.25em;
                    // transform: scale(1.25) !important;
                    transform-origin: center;
                }

                &:focus,
                &:active {
                    background: rgba(lime, 0.8);
                    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.875), 1px 1px 0 0 rgba(0, 0, 0, 0.5), 1px 1px 0 0 rgba(255, 0, 0, 1);
                }

                a {
                    color: #000 !important;
                }
            }

            a {
                color: lime;
                font-weight: 300;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: cyan;
                }
            }
        }
    }

    &#hacker {
        top: clamp(200em, 420vh, 100em);
        background-color: hsla(46, 34%, 71%, 0.125);
       

        @media (min-width: 40rem) {

            left: -2em;
            right: unset;
        }

        .nextSreen {
            z-index: 2;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background: url(../images/poster_exoskeleton__lounging2.gif);
            background-color: rgba(pink, 0.4);
            background-size: 100%;

            a {
                // filter: url(#pixelate);
                background: rgb(255, 251, 238);
                border: 1px solid wheat;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                position: absolute;
                border-radius: 2em;
                white-space: nowrap;
                img {
                    width: 72px;
                    // mix-blend-mode: color-burn;
                }

                &:hover {
                    scale: 0.9875;
                    border-radius: 1.785em;
                    background: white;
                }
            }
        }

        >div {
            position: relative;
            z-index: 11;
            background: url(../images/poster_exoskeleton.gif);
            background-size: 100%;
            position: relative;
            background-color: transparent;
            overflow: hidden;
            border: 1px solid rgba(Tan, 0.75);

            .window__header {
                color: wheat;
                border-bottom: 0 solid wheat !important;

                .popup-close-button {
                    color: wheat;
                }
            }

            .window__contents {
                min-height: 20em;
                display: grid;
                align-content: end;
                justify-items: end;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                image-rendering: pixelated;
                mix-blend-mode: luminosity;

                background-size: contain;
                position: absolute;
                z-index: -1;
            }

            &:before {
                opacity: 0;
                background-image: url(../images/bomb-diffusion-2.gif);
            }
        }
    }

    &#list {
        top: clamp(32em, 45vh, 50em);
        background-color: hsl(106, 100%, 50%, 0.07);
        visibility: hidden;
        
        @media (min-width: 40rem) {
            right: 1em;
        }

        >div {
            background-size: 100%;
            position: relative;
            background-color: transparent;
            overflow: hidden;

            .window__header {

                .popup-close-button {}
            }

            .window__contents {
                min-height: 20em;
                display: grid;
                align-content: end;
                justify-items: end;
                color: lime;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                image-rendering: pixelated;
                mix-blend-mode: luminosity;

                background-size: contain;
                position: absolute;
                z-index: -1;
            }

            &:before {
                opacity: 0;
                background-image: url(../images/bomb-diffusion-2.gif);
            }
        }
    }


}


// // // Styling applied when you drag the card - toggle `dragging` class when you grab the window 
.window.dragging {

    .nextSreen {
        opacity: 0 !important;
    }

    >div {

        border: 1px solid mix(lime, #fff, 60%) !important;
        animation: jiggle 0.5s;
        animation-iteration-count: infinite;
        box-shadow: 0.3em 2.2em 2.6em 0.1em rgba(#67d167, 0.2), 1.2em 1.6em 1.5em 0.5em rgba(lime, 0.25),
            0em 0.5em 1.5em 0.25em rgba(lime, 0.1);

        .window__header {
            border-bottom: 1px solid mix(lime, #fff, 60%) !important;

            .popup-close-button {
                color: mix(lime, #fff, 60%) !important;
            }
        }

        .popup-close-button,
        .window__header abbr .txt,
        .window__contents {
            color: mix(#fff, lime, 15%);
        }
    }
}

.window.win__two.dragging {
    >div {

        border: 1px solid mix(cyan, #fff, 60%) !important;
        animation: jiggle 0.5s;
        animation-iteration-count: infinite;
        box-shadow: 0.3em 2.2em 2.6em 0.1em rgba(cyan, 0.2), 1.2em 1.6em 1.5em 0.5em rgba(cyan, 0.25),
            0em 0.5em 1.5em 0.25em rgba(cyan, 0.1);

        .window__header {
            border-bottom: 1px solid mix(cyan, #fff, 60%) !important;

            .popup-close-button {
                color: mix(cyan, #fff, 60%) !important;
            }
        }

        .window__contents {
            color: cyan !important;

            h1,
            h2,
            h3,
            p {
                color: cyan !important;
            }
        }

        .popup-close-button,
        .window__header abbr .txt,
        .window__contents {
            color: mix(#fff, cyan, 15%);
        }
    }
}

// // .window.draggingAlt {
// //     > div {
// //         background: mix($whiteColor, var(--color-primary), 99%) !important;
// //         border: 1px solid mix(magenta, $secondaryColor, 60%) !important;
// //         animation: jiggle 0.5s;
// //         animation-iteration-count: infinite;
// //         box-shadow: 0.3em 2.2em 2.6em 0.1em rgba(magenta, 0.1), 1.2em 1.6em 1.5em 0.5em rgba(magenta, 0.25),
// //             0em 0.5em 1.5em 0.25em rgba(magenta, 0.1);

// //         .window__header {
// //             border-bottom: 1px solid mix(magenta, $secondaryColor, 60%) !important;
// //         }
// //         .popup-close-button,
// //         .window__header abbr .txt,
// //         .window__contents {
// //             color: mix($bodyColor, magenta, 15%);
// //         }
// //     }
// // }

// // // Styling applied when you drag the card - toggle `dragging` class when you grab the window 
// // .window.dragging {
// //     > div {

// //         border: 1px solid lime !important;
// //         animation: jiggle 0.5s;
// //         animation-iteration-count: infinite;
// //         box-shadow: 0.3em 2.2em 2.6em 0.1em lime, 1.2em 1.6em 1.5em 0.5em lime,
// //             0em 0.5em 1.5em 0.25em lime;

// //         .window__header {
// //             border-bottom: 1px solid lime !important;
// //             .popup-close-button {
// //                 color: green !important;
// //             }
// //         }
// //         .popup-close-button,
// //         .window__header abbr .txt,
// //         .window__contents {
// //             color: green;
// //         }
// //     }
// // }


.textPanel {
    box-shadow:  1px 1px 0 0 rgba(255, 255, 255, 0.075), inset 1px 1px 0 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0,0,0,0.1);
    margin: calc(var(--space) * 2) auto 0;
    padding: calc(var(--space) / 3);
    border-radius: calc(var(--space) / 6);
    font-size: 0.875em;
    line-height: 1.375em;
    font-family: "SF Mono", "Segoe-Mono-Boot", monospace, monospace;

    p {
        margin: 0 0 1em;
    }
}